.monaco-editor {
  --monaco-monospace-font: "SF Mono", Monaco, Menlo, Consolas, "Ubuntu Mono", "Liberation Mono", "DejaVu Sans Mono", "Courier New", monospace;
  font-family: -apple-system, BlinkMacSystemFont, Segoe WPC, Segoe UI, HelveticaNeue-Light, system-ui, Ubuntu, Droid Sans, sans-serif;
}

.monaco-menu .monaco-action-bar.vertical .action-item .action-menu-item:focus .action-label, .monaco-editor.vs-dark .monaco-menu .monaco-action-bar.vertical .action-menu-item:focus .action-label, .monaco-editor.hc-black .monaco-menu .monaco-action-bar.vertical .action-menu-item:focus .action-label, .monaco-editor.hc-light .monaco-menu .monaco-action-bar.vertical .action-menu-item:focus .action-label {
  stroke-width: 1.2px;
}

.monaco-hover p {
  margin: 0;
}

.monaco-aria-container {
  height: 1px;
  width: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  margin: -1px;
  padding: 0;
  top: 0;
  overflow: hidden;
  position: absolute !important;
}

.monaco-editor.hc-black, .monaco-editor.hc-light {
  -ms-high-contrast-adjust: none;
}

@media screen and (-ms-high-contrast: active) {
  .monaco-editor.vs .view-overlays .current-line, .monaco-editor.vs-dark .view-overlays .current-line {
    border-left: 0;
    border-right: 0;
    border-color: windowtext !important;
  }

  .monaco-editor.vs .cursor, .monaco-editor.vs-dark .cursor {
    background-color: windowtext !important;
  }

  .monaco-editor.vs .dnd-target, .monaco-editor.vs-dark .dnd-target {
    border-color: windowtext !important;
  }

  .monaco-editor.vs .selected-text, .monaco-editor.vs-dark .selected-text {
    background-color: highlight !important;
  }

  .monaco-editor.vs .view-line, .monaco-editor.vs-dark .view-line {
    -ms-high-contrast-adjust: none;
  }

  .monaco-editor.vs .view-line span, .monaco-editor.vs-dark .view-line span {
    color: windowtext !important;
  }

  .monaco-editor.vs .view-line span.inline-selected-text, .monaco-editor.vs-dark .view-line span.inline-selected-text {
    color: highlighttext !important;
  }

  .monaco-editor.vs .view-overlays, .monaco-editor.vs-dark .view-overlays {
    -ms-high-contrast-adjust: none;
  }

  .monaco-editor.vs .selectionHighlight, .monaco-editor.vs-dark .selectionHighlight, .monaco-editor.vs .wordHighlight, .monaco-editor.vs-dark .wordHighlight, .monaco-editor.vs .wordHighlightStrong, .monaco-editor.vs-dark .wordHighlightStrong, .monaco-editor.vs .reference-decoration, .monaco-editor.vs-dark .reference-decoration {
    box-sizing: border-box;
    border: 2px dotted highlight !important;
    background: none !important;
  }

  .monaco-editor.vs .rangeHighlight, .monaco-editor.vs-dark .rangeHighlight {
    box-sizing: border-box;
    border: 1px dotted activeborder !important;
    background: none !important;
  }

  .monaco-editor.vs .bracket-match, .monaco-editor.vs-dark .bracket-match {
    border-color: windowtext !important;
    background: none !important;
  }

  .monaco-editor.vs .findMatch, .monaco-editor.vs-dark .findMatch, .monaco-editor.vs .currentFindMatch, .monaco-editor.vs-dark .currentFindMatch {
    box-sizing: border-box;
    border: 2px dotted activeborder !important;
    background: none !important;
  }

  .monaco-editor.vs .find-widget, .monaco-editor.vs-dark .find-widget {
    border: 1px solid windowtext;
  }

  .monaco-editor.vs .monaco-list .monaco-list-row, .monaco-editor.vs-dark .monaco-list .monaco-list-row {
    -ms-high-contrast-adjust: none;
    color: windowtext !important;
  }

  .monaco-editor.vs .monaco-list .monaco-list-row.focused, .monaco-editor.vs-dark .monaco-list .monaco-list-row.focused {
    color: highlighttext !important;
    background-color: highlight !important;
  }

  .monaco-editor.vs .monaco-list .monaco-list-row:hover, .monaco-editor.vs-dark .monaco-list .monaco-list-row:hover {
    border: 1px solid highlight;
    box-sizing: border-box;
    background: none !important;
  }

  .monaco-editor.vs .monaco-scrollable-element > .scrollbar, .monaco-editor.vs-dark .monaco-scrollable-element > .scrollbar {
    -ms-high-contrast-adjust: none;
    border: 1px solid windowtext;
    box-sizing: border-box;
    background: background !important;
  }

  .monaco-editor.vs .monaco-scrollable-element > .scrollbar > .slider, .monaco-editor.vs-dark .monaco-scrollable-element > .scrollbar > .slider {
    background: windowtext !important;
  }

  .monaco-editor.vs .monaco-scrollable-element > .scrollbar > .slider:hover, .monaco-editor.vs-dark .monaco-scrollable-element > .scrollbar > .slider:hover, .monaco-editor.vs .monaco-scrollable-element > .scrollbar > .slider.active, .monaco-editor.vs-dark .monaco-scrollable-element > .scrollbar > .slider.active {
    background: highlight !important;
  }

  .monaco-editor.vs .decorationsOverviewRuler, .monaco-editor.vs-dark .decorationsOverviewRuler {
    opacity: 0;
  }

  .monaco-editor.vs .minimap, .monaco-editor.vs-dark .minimap {
    display: none;
  }

  .monaco-editor.vs .squiggly-d-error, .monaco-editor.vs-dark .squiggly-d-error {
    border-bottom: 4px double #e47777;
    background: none !important;
  }

  .monaco-editor.vs .squiggly-c-warning, .monaco-editor.vs-dark .squiggly-c-warning, .monaco-editor.vs .squiggly-b-info, .monaco-editor.vs-dark .squiggly-b-info {
    border-bottom: 4px double #71b771;
  }

  .monaco-editor.vs .squiggly-a-hint, .monaco-editor.vs-dark .squiggly-a-hint {
    border-bottom: 4px double #6c6c6c;
  }

  .monaco-editor.vs .monaco-menu .monaco-action-bar.vertical .action-menu-item:focus .action-label, .monaco-editor.vs-dark .monaco-menu .monaco-action-bar.vertical .action-menu-item:focus .action-label {
    -ms-high-contrast-adjust: none;
    color: highlighttext !important;
    background-color: highlight !important;
  }

  .monaco-editor.vs .monaco-menu .monaco-action-bar.vertical .action-menu-item:hover .action-label, .monaco-editor.vs-dark .monaco-menu .monaco-action-bar.vertical .action-menu-item:hover .action-label {
    -ms-high-contrast-adjust: none;
    border: 1px solid highlight;
    box-sizing: border-box;
    background: none !important;
  }

  .monaco-diff-editor.vs .diffOverviewRuler, .monaco-diff-editor.vs-dark .diffOverviewRuler {
    display: none;
  }

  .monaco-editor.vs .line-insert, .monaco-editor.vs-dark .line-insert, .monaco-editor.vs .line-delete, .monaco-editor.vs-dark .line-delete {
    box-sizing: border-box;
    border: 1px solid highlight !important;
    background: none !important;
  }

  .monaco-editor.vs .char-insert, .monaco-editor.vs-dark .char-insert, .monaco-editor.vs .char-delete, .monaco-editor.vs-dark .char-delete {
    background: none !important;
  }
}

.monaco-aria-container {
  position: absolute;
  left: -999em;
}

::-ms-clear {
  display: none;
}

.monaco-editor .editor-widget input {
  color: inherit;
}

.monaco-editor {
  -webkit-text-size-adjust: 100%;
  position: relative;
  overflow: visible;
}

.monaco-editor .overflow-guard {
  position: relative;
  overflow: hidden;
}

.monaco-editor .view-overlays {
  position: absolute;
  top: 0;
}

.monaco-editor .inputarea {
  min-width: 0;
  min-height: 0;
  resize: none;
  color: #0000;
  background-color: #0000;
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  overflow: hidden;
  outline: none !important;
}

.monaco-editor .inputarea.ime-input {
  z-index: 10;
}

.monaco-editor .margin-view-overlays .line-numbers {
  font-variant-numeric: tabular-nums;
  text-align: right;
  vertical-align: middle;
  box-sizing: border-box;
  cursor: default;
  height: 100%;
  display: inline-block;
  position: absolute;
}

.monaco-editor .relative-current-line-number {
  text-align: left;
  width: 100%;
  display: inline-block;
}

.monaco-editor .margin-view-overlays .line-numbers.lh-odd {
  margin-top: 1px;
}

.monaco-mouse-cursor-text {
  cursor: text;
}

.monaco-editor .view-overlays .current-line, .monaco-editor .margin-view-overlays .current-line {
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.monaco-editor .margin-view-overlays .current-line.current-line-margin.current-line-margin-both {
  border-right: 0;
}

.monaco-editor .lines-content .cdr {
  position: absolute;
}

.monaco-scrollable-element > .scrollbar > .scra {
  cursor: pointer;
  font-size: 11px !important;
}

.monaco-scrollable-element > .visible {
  opacity: 1;
  background: none;
  transition: opacity .1s linear;
}

.monaco-scrollable-element > .invisible {
  opacity: 0;
  pointer-events: none;
}

.monaco-scrollable-element > .invisible.fade {
  transition: opacity .8s linear;
}

.monaco-scrollable-element > .shadow {
  display: none;
  position: absolute;
}

.monaco-scrollable-element > .shadow.top {
  height: 3px;
  width: 100%;
  display: block;
  top: 0;
  left: 3px;
}

.monaco-scrollable-element > .shadow.left {
  height: 100%;
  width: 3px;
  display: block;
  top: 3px;
  left: 0;
}

.monaco-scrollable-element > .shadow.top-left-corner {
  height: 3px;
  width: 3px;
  display: block;
  top: 0;
  left: 0;
}

.monaco-editor .glyph-margin {
  position: absolute;
  top: 0;
}

.monaco-editor .margin-view-overlays .cgmr {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.monaco-editor .lines-content .core-guide {
  box-sizing: border-box;
  position: absolute;
}

.mtkcontrol {
  color: #fff !important;
  background: #960000 !important;
}

.monaco-editor.no-user-select .lines-content, .monaco-editor.no-user-select .view-line, .monaco-editor.no-user-select .view-lines {
  -webkit-user-select: none;
  user-select: none;
}

.monaco-editor.enable-user-select {
  user-select: initial;
  -webkit-user-select: initial;
  -ms-user-select: initial;
}

.monaco-editor .view-lines {
  white-space: nowrap;
}

.monaco-editor .view-line {
  width: 100%;
  position: absolute;
}

.monaco-editor .mtkz {
  display: inline-block;
}

.monaco-editor .lines-decorations {
  background: #fff;
  position: absolute;
  top: 0;
}

.monaco-editor .margin-view-overlays .cldr {
  height: 100%;
  position: absolute;
}

.monaco-editor .margin-view-overlays .cmdr {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
}

.monaco-editor .minimap.slider-mouseover .minimap-slider {
  opacity: 0;
  transition: opacity .1s linear;
}

.monaco-editor .minimap.slider-mouseover:hover .minimap-slider, .monaco-editor .minimap.slider-mouseover .minimap-slider.active {
  opacity: 1;
}

.monaco-editor .minimap-shadow-hidden {
  width: 0;
  position: absolute;
}

.monaco-editor .minimap-shadow-visible {
  width: 6px;
  position: absolute;
  left: -6px;
}

.monaco-editor.no-minimap-shadow .minimap-shadow-visible {
  width: 1px;
  position: absolute;
  left: -1px;
}

.minimap.autohide {
  opacity: 0;
  transition: opacity .5s;
}

.minimap.autohide:hover {
  opacity: 1;
}

.monaco-editor .overlayWidgets {
  position: absolute;
  top: 0;
  left: 0;
}

.monaco-editor .view-ruler {
  position: absolute;
  top: 0;
}

.monaco-editor .scroll-decoration {
  height: 6px;
  position: absolute;
  top: 0;
  left: 0;
}

.monaco-editor .lines-content .cslr {
  position: absolute;
}

.monaco-editor .top-left-radius {
  border-top-left-radius: 3px;
}

.monaco-editor .bottom-left-radius {
  border-bottom-left-radius: 3px;
}

.monaco-editor .top-right-radius {
  border-top-right-radius: 3px;
}

.monaco-editor .bottom-right-radius {
  border-bottom-right-radius: 3px;
}

.monaco-editor.hc-black .top-left-radius {
  border-top-left-radius: 0;
}

.monaco-editor.hc-black .bottom-left-radius {
  border-bottom-left-radius: 0;
}

.monaco-editor.hc-black .top-right-radius {
  border-top-right-radius: 0;
}

.monaco-editor.hc-black .bottom-right-radius {
  border-bottom-right-radius: 0;
}

.monaco-editor.hc-light .top-left-radius {
  border-top-left-radius: 0;
}

.monaco-editor.hc-light .bottom-left-radius {
  border-bottom-left-radius: 0;
}

.monaco-editor.hc-light .top-right-radius {
  border-top-right-radius: 0;
}

.monaco-editor.hc-light .bottom-right-radius {
  border-bottom-right-radius: 0;
}

.monaco-editor .cursors-layer {
  position: absolute;
  top: 0;
}

.monaco-editor .cursors-layer > .cursor {
  position: absolute;
  overflow: hidden;
}

.monaco-editor .cursors-layer.cursor-smooth-caret-animation > .cursor {
  transition: all 80ms;
}

.monaco-editor .cursors-layer.cursor-block-outline-style > .cursor {
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  background: none !important;
}

.monaco-editor .cursors-layer.cursor-underline-style > .cursor {
  box-sizing: border-box;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  background: none !important;
}

.monaco-editor .cursors-layer.cursor-underline-thin-style > .cursor {
  box-sizing: border-box;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  background: none !important;
}

@keyframes monaco-cursor-smooth {
  0%, 20% {
    opacity: 1;
  }

  60%, 100% {
    opacity: 0;
  }
}

@keyframes monaco-cursor-phase {
  0%, 20% {
    opacity: 1;
  }

  90%, 100% {
    opacity: 0;
  }
}

@keyframes monaco-cursor-expand {
  0%, 20% {
    transform: scaleY(1);
  }

  80%, 100% {
    transform: scaleY(0);
  }
}

.cursor-smooth {
  animation: .5s ease-in-out 20 alternate monaco-cursor-smooth;
}

.cursor-phase {
  animation: .5s ease-in-out 20 alternate monaco-cursor-phase;
}

.cursor-expand > .cursor {
  animation: .5s ease-in-out 20 alternate monaco-cursor-expand;
}

.monaco-editor .blockDecorations-container {
  position: absolute;
  top: 0;
}

.monaco-editor .blockDecorations-block {
  box-sizing: border-box;
  position: absolute;
}

.monaco-diff-editor .diffOverview {
  z-index: 9;
}

.monaco-diff-editor .diffOverview .diffViewport {
  z-index: 10;
}

.monaco-diff-editor.vs .diffOverview {
  background: #00000008;
}

.monaco-diff-editor.vs-dark .diffOverview {
  background: #ffffff03;
}

.monaco-scrollable-element.modified-in-monaco-diff-editor.vs .scrollbar, .monaco-scrollable-element.modified-in-monaco-diff-editor.vs-dark .scrollbar, .monaco-scrollable-element.modified-in-monaco-diff-editor.hc-black .scrollbar, .monaco-scrollable-element.modified-in-monaco-diff-editor.hc-light .scrollbar {
  background: none;
}

.monaco-scrollable-element.modified-in-monaco-diff-editor .slider {
  z-index: 10;
}

.modified-in-monaco-diff-editor .slider.active {
  background: #ababab66;
}

.modified-in-monaco-diff-editor.hc-black .slider.active, .modified-in-monaco-diff-editor.hc-light .slider.active {
  background: none;
}

.monaco-editor .insert-sign, .monaco-diff-editor .insert-sign, .monaco-editor .delete-sign, .monaco-diff-editor .delete-sign {
  align-items: center;
  opacity: .7 !important;
  font-size: 11px !important;
  display: flex !important;
}

.monaco-editor.hc-black .insert-sign, .monaco-diff-editor.hc-black .insert-sign, .monaco-editor.hc-black .delete-sign, .monaco-diff-editor.hc-black .delete-sign, .monaco-editor.hc-light .insert-sign, .monaco-diff-editor.hc-light .insert-sign, .monaco-editor.hc-light .delete-sign, .monaco-diff-editor.hc-light .delete-sign {
  opacity: 1;
}

.monaco-editor .inline-deleted-margin-view-zone, .monaco-editor .inline-added-margin-view-zone {
  text-align: right;
}

.monaco-editor .arrow-revert-change {
  z-index: 10;
  position: absolute;
}

.monaco-editor .arrow-revert-change:hover {
  cursor: pointer;
}

.monaco-editor .view-zones .view-lines .view-line span {
  display: inline-block;
}

.monaco-editor .margin-view-zones .lightbulb-glyph:hover {
  cursor: pointer;
}

:root {
  --sash-size: 4px;
}

.monaco-sash {
  z-index: 35;
  touch-action: none;
  position: absolute;
}

.monaco-sash.disabled {
  pointer-events: none;
}

.monaco-sash.mac.vertical {
  cursor: col-resize;
}

.monaco-sash.vertical.minimum {
  cursor: e-resize;
}

.monaco-sash.vertical.maximum {
  cursor: w-resize;
}

.monaco-sash.mac.horizontal {
  cursor: row-resize;
}

.monaco-sash.horizontal.minimum {
  cursor: s-resize;
}

.monaco-sash.horizontal.maximum {
  cursor: n-resize;
}

.monaco-sash.disabled {
  cursor: default !important;
  pointer-events: none !important;
}

.monaco-sash.vertical {
  cursor: ew-resize;
  width: var(--sash-size);
  height: 100%;
  top: 0;
}

.monaco-sash.horizontal {
  cursor: ns-resize;
  width: 100%;
  height: var(--sash-size);
  left: 0;
}

.monaco-sash:not(.disabled) > .orthogonal-drag-handle {
  content: " ";
  height: calc(var(--sash-size) * 2);
  width: calc(var(--sash-size) * 2);
  z-index: 100;
  cursor: all-scroll;
  display: block;
  position: absolute;
}

.monaco-sash.horizontal.orthogonal-edge-north:not(.disabled) > .orthogonal-drag-handle.start, .monaco-sash.horizontal.orthogonal-edge-south:not(.disabled) > .orthogonal-drag-handle.end {
  cursor: nwse-resize;
}

.monaco-sash.horizontal.orthogonal-edge-north:not(.disabled) > .orthogonal-drag-handle.end, .monaco-sash.horizontal.orthogonal-edge-south:not(.disabled) > .orthogonal-drag-handle.start {
  cursor: nesw-resize;
}

.monaco-sash.vertical > .orthogonal-drag-handle.start {
  left: calc(var(--sash-size) * -.5);
  top: calc(var(--sash-size) * -1);
}

.monaco-sash.vertical > .orthogonal-drag-handle.end {
  left: calc(var(--sash-size) * -.5);
  bottom: calc(var(--sash-size) * -1);
}

.monaco-sash.horizontal > .orthogonal-drag-handle.start {
  top: calc(var(--sash-size) * -.5);
  left: calc(var(--sash-size) * -1);
}

.monaco-sash.horizontal > .orthogonal-drag-handle.end {
  top: calc(var(--sash-size) * -.5);
  right: calc(var(--sash-size) * -1);
}

.monaco-sash:before {
  content: "";
  pointer-events: none;
  width: 100%;
  height: 100%;
  background: none;
  transition: background-color .1s ease-out;
  position: absolute;
}

.monaco-sash.vertical:before {
  width: var(--sash-hover-size);
  left: calc(50% - (var(--sash-hover-size) / 2) );
}

.monaco-sash.horizontal:before {
  height: var(--sash-hover-size);
  top: calc(50% - (var(--sash-hover-size) / 2) );
}

.pointer-events-disabled {
  pointer-events: none !important;
}

.monaco-sash.debug {
  background: #0ff;
}

.monaco-sash.debug.disabled {
  background: #0ff3;
}

.monaco-sash.debug:not(.disabled) > .orthogonal-drag-handle {
  background: red;
}

.monaco-diff-editor .diff-review-line-number {
  text-align: right;
  display: inline-block;
}

.monaco-diff-editor .diff-review {
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
}

.monaco-diff-editor .diff-review-summary {
  padding-left: 10px;
}

.monaco-diff-editor .diff-review-shadow {
  position: absolute;
}

.monaco-diff-editor .diff-review-row {
  white-space: pre;
}

.monaco-diff-editor .diff-review-table {
  min-width: 100%;
  display: table;
}

.monaco-diff-editor .diff-review-row {
  width: 100%;
  display: table-row;
}

.monaco-diff-editor .diff-review-spacer {
  width: 10px;
  vertical-align: middle;
  display: inline-block;
}

.monaco-diff-editor .diff-review-spacer > .codicon {
  font-size: 9px !important;
}

.monaco-diff-editor .diff-review-actions {
  display: inline-block;
  position: absolute;
  top: 2px;
  right: 10px;
}

.monaco-diff-editor .diff-review-actions .action-label {
  width: 16px;
  height: 16px;
  margin: 2px 0;
}

.monaco-action-bar {
  white-space: nowrap;
  height: 100%;
}

.monaco-action-bar .actions-container {
  height: 100%;
  width: 100%;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  display: flex;
}

.monaco-action-bar.vertical .actions-container {
  display: inline-block;
}

.monaco-action-bar .action-item {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: block;
  position: relative;
}

.monaco-action-bar .action-item.disabled {
  cursor: default;
}

.monaco-action-bar .action-item .icon, .monaco-action-bar .action-item .codicon {
  display: block;
}

.monaco-action-bar .action-item .codicon {
  width: 16px;
  height: 16px;
  align-items: center;
  display: flex;
}

.monaco-action-bar .action-label {
  border-radius: 5px;
  padding: 3px;
  font-size: 11px;
}

.monaco-action-bar .action-item.disabled .action-label, .monaco-action-bar .action-item.disabled .action-label:before, .monaco-action-bar .action-item.disabled .action-label:hover {
  opacity: .6;
}

.monaco-action-bar.vertical {
  text-align: left;
}

.monaco-action-bar.vertical .action-item {
  display: block;
}

.monaco-action-bar.vertical .action-label.separator {
  border-bottom: 1px solid #bbb;
  margin-left: .8em;
  margin-right: .8em;
  padding-top: 1px;
  display: block;
}

.monaco-action-bar .action-item .action-label.separator {
  width: 1px;
  height: 16px;
  cursor: default;
  min-width: 1px;
  background-color: #bbb;
  padding: 0;
  margin: 5px 4px !important;
}

.secondary-actions .monaco-action-bar .action-label {
  margin-left: 6px;
}

.monaco-action-bar .action-item.select-container {
  max-width: 170px;
  min-width: 60px;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  display: flex;
  overflow: hidden;
}

.monaco-action-bar .action-item.action-dropdown-item {
  display: flex;
}

.monaco-action-bar .action-item.action-dropdown-item > .action-label {
  margin-right: 1px;
}

.context-view {
  position: absolute;
}

.context-view.fixed {
  all: initial;
  color: inherit;
  font-family: inherit;
  font-size: 13px;
  position: fixed;
}

@font-face {
  font-family: codicon;
  font-display: block;
  src: url("codicon.9f1f6512.ttf") format("truetype");
}

.codicon[class*="codicon-"] {
  text-rendering: auto;
  text-align: center;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
  user-select: none;
  font: 16px / 1 codicon;
  text-decoration: none;
  display: inline-block;
}

.codicon-wrench-subaction {
  opacity: .5;
}

@keyframes codicon-spin {
  100% {
    transform: rotate(360deg);
  }
}

.codicon-sync.codicon-modifier-spin, .codicon-loading.codicon-modifier-spin, .codicon-gear.codicon-modifier-spin, .codicon-notebook-state-executing.codicon-modifier-spin {
  animation: 1.5s steps(30, end) infinite codicon-spin;
}

.codicon-modifier-disabled {
  opacity: .4;
}

.codicon-loading, .codicon-tree-item-loading:before {
  animation-duration: 1s !important;
  animation-timing-function: cubic-bezier(.53, .21, .29, .67) !important;
}

.quick-input-widget {
  font-size: 13px;
}

.quick-input-widget .monaco-highlighted-label .highlight, .quick-input-widget .monaco-highlighted-label .highlight {
  color: #0066bf;
}

.vs .quick-input-widget .monaco-list-row.focused .monaco-highlighted-label .highlight, .vs .quick-input-widget .monaco-list-row.focused .monaco-highlighted-label .highlight {
  color: #9dddff;
}

.vs-dark .quick-input-widget .monaco-highlighted-label .highlight, .vs-dark .quick-input-widget .monaco-highlighted-label .highlight {
  color: #0097fb;
}

.hc-black .quick-input-widget .monaco-highlighted-label .highlight, .hc-black .quick-input-widget .monaco-highlighted-label .highlight {
  color: #f38518;
}

.hc-light .quick-input-widget .monaco-highlighted-label .highlight, .hc-light .quick-input-widget .monaco-highlighted-label .highlight {
  color: #0f4a85;
}

.monaco-keybinding > .monaco-keybinding-key {
  color: #555;
  background-color: #ddd6;
  border: 1px solid #ccc6;
  border-bottom-color: #bbb6;
  box-shadow: inset 0 -1px #bbb6;
}

.hc-black .monaco-keybinding > .monaco-keybinding-key {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border: 1px solid #6fc3df;
}

.hc-light .monaco-keybinding > .monaco-keybinding-key {
  box-shadow: none;
  color: #292929;
  background-color: #0000;
  border: 1px solid #0f4a85;
}

.vs-dark .monaco-keybinding > .monaco-keybinding-key {
  color: #ccc;
  background-color: #8080802b;
  border: 1px solid #3339;
  border-bottom-color: #4449;
  box-shadow: inset 0 -1px #4449;
}

.monaco-text-button {
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 4px;
  display: flex;
}

.monaco-text-button:focus {
  outline-offset: 2px !important;
}

.monaco-text-button:hover {
  text-decoration: none !important;
}

.monaco-button.disabled:focus, .monaco-button.disabled {
  cursor: default;
  opacity: .4 !important;
}

.monaco-text-button > .codicon {
  margin: 0 .2em;
  color: inherit !important;
}

.monaco-button-dropdown {
  cursor: pointer;
  display: flex;
}

.monaco-button-dropdown.disabled {
  cursor: default;
}

.monaco-button-dropdown > .monaco-button:focus {
  outline-offset: -1px !important;
}

.monaco-button-dropdown.disabled > .monaco-button.disabled, .monaco-button-dropdown.disabled > .monaco-button.disabled:focus, .monaco-button-dropdown.disabled > .monaco-button-dropdown-separator {
  opacity: .4 !important;
}

.monaco-button-dropdown > .monaco-button.monaco-text-button {
  border-right-width: 0 !important;
}

.monaco-button-dropdown .monaco-button-dropdown-separator {
  cursor: default;
  padding: 4px 0;
}

.monaco-button-dropdown .monaco-button-dropdown-separator > div {
  height: 100%;
  width: 1px;
}

.monaco-button-dropdown > .monaco-button.monaco-dropdown-button {
  border-left-width: 0 !important;
}

.monaco-description-button {
  flex-direction: column;
}

.monaco-description-button .monaco-button-label {
  font-weight: 500;
}

.monaco-description-button .monaco-button-description {
  font-style: italic;
}

.monaco-description-button .monaco-button-label, .monaco-description-button .monaco-button-description {
  justify-content: center;
  align-items: center;
  display: flex;
}

.monaco-description-button .monaco-button-label > .codicon, .monaco-description-button .monaco-button-description > .codicon {
  margin: 0 .2em;
  color: inherit !important;
}

.monaco-count-badge {
  min-width: 18px;
  min-height: 18px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 11px;
  padding: 3px 6px;
  font-size: 11px;
  font-weight: normal;
  line-height: 11px;
  display: inline-block;
}

.monaco-count-badge.long {
  min-height: auto;
  border-radius: 2px;
  padding: 2px 3px;
  line-height: normal;
}

.monaco-progress-container {
  width: 100%;
  height: 5px;
  overflow: hidden;
}

.monaco-progress-container .progress-bit {
  width: 2%;
  height: 5px;
  display: none;
  position: absolute;
  left: 0;
}

.monaco-progress-container.active .progress-bit {
  display: inherit;
}

.monaco-progress-container.discrete .progress-bit {
  transition: width .1s linear;
  left: 0;
}

.monaco-progress-container.discrete.done .progress-bit {
  width: 100%;
}

.monaco-progress-container.infinite .progress-bit {
  animation-name: progress;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  transform: translate3d(0, 0, 0);
}

.monaco-progress-container.infinite.infinite-long-running .progress-bit {
  animation-timing-function: steps(100, end);
}

@keyframes progress {
  from {
    transform: translateX(0%)scaleX(1);
  }

  50% {
    transform: translateX(2500%)scaleX(3);
  }

  to {
    transform: translateX(4900%)scaleX(1);
  }
}

.quick-input-widget {
  width: 600px;
  z-index: 2550;
  -webkit-app-region: no-drag;
  margin-left: -300px;
  position: absolute;
  left: 50%;
}

.quick-input-titlebar {
  align-items: center;
  display: flex;
}

.quick-input-left-action-bar {
  flex: 1;
  margin-left: 4px;
  display: flex;
}

.quick-input-title {
  text-align: center;
  text-overflow: ellipsis;
  padding: 3px 0;
  overflow: hidden;
}

.quick-input-right-action-bar {
  flex: 1;
  margin-right: 4px;
  display: flex;
}

.quick-input-right-action-bar > .actions-container {
  justify-content: flex-end;
}

.quick-input-titlebar .monaco-action-bar .action-label.codicon {
  background-position: center;
  background-repeat: no-repeat;
  padding: 2px;
}

.quick-input-description {
  margin: 6px;
}

.quick-input-header .quick-input-description {
  margin: 4px 2px;
}

.quick-input-header {
  margin-bottom: -2px;
  padding: 6px 6px 0;
  display: flex;
}

.quick-input-widget.hidden-input .quick-input-header {
  margin-bottom: 0;
  padding: 0;
}

.quick-input-and-message {
  min-width: 0;
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  position: relative;
}

.quick-input-check-all {
  align-self: center;
  margin: 0;
}

.quick-input-filter {
  flex-grow: 1;
  display: flex;
  position: relative;
}

.quick-input-box {
  flex-grow: 1;
}

.quick-input-widget.show-checkboxes .quick-input-box, .quick-input-widget.show-checkboxes .quick-input-message {
  margin-left: 5px;
}

.quick-input-visible-count {
  position: absolute;
  left: -10000px;
}

.quick-input-count {
  align-self: center;
  align-items: center;
  display: flex;
  position: absolute;
  right: 4px;
}

.quick-input-count .monaco-count-badge {
  vertical-align: middle;
  min-height: auto;
  border-radius: 2px;
  padding: 2px 4px;
  line-height: normal;
}

.quick-input-action {
  margin-left: 6px;
}

.quick-input-action .monaco-text-button {
  height: 27.5px;
  align-items: center;
  padding: 0 6px;
  font-size: 11px;
  display: flex;
}

.quick-input-message {
  overflow-wrap: break-word;
  margin-top: -1px;
  padding: 5px;
}

.quick-input-message > .codicon {
  vertical-align: text-bottom;
  margin: 0 .2em;
}

.quick-input-progress.monaco-progress-container {
  position: relative;
}

.quick-input-progress.monaco-progress-container, .quick-input-progress.monaco-progress-container .progress-bit {
  height: 2px;
}

.quick-input-list {
  margin-top: 6px;
  padding: 0 1px 1px;
  line-height: 22px;
}

.quick-input-widget.hidden-input .quick-input-list {
  margin-top: 0;
}

.quick-input-list .monaco-list {
  max-height: 440px;
  overflow: hidden;
}

.quick-input-list .quick-input-list-entry {
  box-sizing: border-box;
  height: 100%;
  padding: 0 6px;
  display: flex;
  overflow: hidden;
}

.quick-input-list .quick-input-list-entry.quick-input-list-separator-border {
  border-top-style: solid;
  border-top-width: 1px;
}

.quick-input-list .monaco-list-row[data-index="0"] .quick-input-list-entry.quick-input-list-separator-border {
  border-top-style: none;
}

.quick-input-list .quick-input-list-label {
  height: 100%;
  flex: 1;
  display: flex;
  overflow: hidden;
}

.quick-input-list .quick-input-list-checkbox {
  align-self: center;
  margin: 0;
}

.quick-input-list .quick-input-list-rows {
  text-overflow: ellipsis;
  height: 100%;
  flex-direction: column;
  flex: 1;
  margin-left: 5px;
  display: flex;
  overflow: hidden;
}

.quick-input-widget.show-checkboxes .quick-input-list .quick-input-list-rows {
  margin-left: 10px;
}

.quick-input-widget .quick-input-list .quick-input-list-checkbox {
  display: none;
}

.quick-input-widget.show-checkboxes .quick-input-list .quick-input-list-checkbox {
  display: inline;
}

.quick-input-list .quick-input-list-rows > .quick-input-list-row {
  align-items: center;
  display: flex;
}

.quick-input-list .quick-input-list-rows > .quick-input-list-row .monaco-icon-label, .quick-input-list .quick-input-list-rows > .quick-input-list-row .monaco-icon-label .monaco-icon-label-container > .monaco-icon-name-container {
  flex: 1;
}

.quick-input-list .quick-input-list-rows > .quick-input-list-row .codicon[class*="codicon-"] {
  vertical-align: text-bottom;
}

.quick-input-list .quick-input-list-rows .monaco-highlighted-label span {
  opacity: 1;
}

.quick-input-list .quick-input-list-entry .quick-input-list-entry-keybinding {
  margin-right: 8px;
}

.quick-input-list .quick-input-list-label-meta {
  opacity: .7;
  text-overflow: ellipsis;
  line-height: normal;
  overflow: hidden;
}

.quick-input-list .monaco-highlighted-label .highlight {
  font-weight: bold;
}

.quick-input-list .quick-input-list-entry .quick-input-list-separator {
  margin-right: 8px;
}

.quick-input-list .quick-input-list-entry-action-bar {
  flex: 0;
  display: flex;
  overflow: visible;
}

.quick-input-list .quick-input-list-entry-action-bar .action-label {
  display: none;
}

.quick-input-list .quick-input-list-entry-action-bar .action-label.codicon {
  margin-right: 4px;
  padding: 0 2px 2px;
}

.quick-input-list .quick-input-list-entry-action-bar {
  margin-top: 1px;
  margin-right: 4px;
}

.quick-input-list .quick-input-list-entry .quick-input-list-entry-action-bar .action-label.always-visible, .quick-input-list .quick-input-list-entry:hover .quick-input-list-entry-action-bar .action-label, .quick-input-list .monaco-list-row.focused .quick-input-list-entry-action-bar .action-label {
  display: flex;
}

.quick-input-list .monaco-list-row.focused .monaco-keybinding-key, .quick-input-list .monaco-list-row.focused .quick-input-list-entry .quick-input-list-separator {
  color: inherit;
}

.quick-input-list .monaco-list-row.focused .monaco-keybinding-key {
  background: none;
}

.monaco-inputbox {
  box-sizing: border-box;
  font-size: inherit;
  padding: 0;
  display: block;
  position: relative;
}

.monaco-inputbox.idle {
  border: 1px solid #0000;
}

.monaco-inputbox > .ibwrapper > .input, .monaco-inputbox > .ibwrapper > .mirror {
  padding: 4px;
}

.monaco-inputbox > .ibwrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.monaco-inputbox > .ibwrapper > .input {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  line-height: inherit;
  font-family: inherit;
  font-size: inherit;
  resize: none;
  color: inherit;
  border: none;
  display: inline-block;
}

.monaco-inputbox > .ibwrapper > input {
  text-overflow: ellipsis;
}

.monaco-inputbox > .ibwrapper > textarea.input {
  -ms-overflow-style: none;
  scrollbar-width: none;
  outline: none;
  display: block;
}

.monaco-inputbox > .ibwrapper > textarea.input::-webkit-scrollbar {
  display: none;
}

.monaco-inputbox > .ibwrapper > textarea.input.empty {
  white-space: nowrap;
}

.monaco-inputbox > .ibwrapper > .mirror {
  width: 100%;
  box-sizing: border-box;
  white-space: pre-wrap;
  visibility: hidden;
  word-wrap: break-word;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.monaco-inputbox-container {
  text-align: right;
}

.monaco-inputbox-container .monaco-inputbox-message {
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  word-wrap: break-word;
  margin-top: -1px;
  padding: .4em;
  font-size: 12px;
  line-height: 17px;
  display: inline-block;
  overflow: hidden;
}

.monaco-inputbox .monaco-action-bar {
  position: absolute;
  top: 4px;
  right: 2px;
}

.monaco-inputbox .monaco-action-bar .action-item {
  margin-left: 2px;
}

.monaco-inputbox .monaco-action-bar .action-item .codicon {
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
}

.monaco-icon-label {
  text-overflow: ellipsis;
  display: flex;
  overflow: hidden;
}

.monaco-icon-label:before {
  width: 16px;
  height: 22px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: top;
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 16px;
  flex-shrink: 0;
  padding-right: 6px;
  display: inline-block;
  line-height: inherit !important;
}

.monaco-icon-label > .monaco-icon-label-container {
  min-width: 0;
  text-overflow: ellipsis;
  flex: 1;
  overflow: hidden;
}

.monaco-icon-label > .monaco-icon-label-container > .monaco-icon-name-container > .label-name {
  color: inherit;
  white-space: pre;
}

.monaco-icon-label > .monaco-icon-label-container > .monaco-icon-name-container > .label-name > .label-separator {
  opacity: .5;
  margin: 0 2px;
}

.monaco-icon-label > .monaco-icon-label-container > .monaco-icon-description-container > .label-description {
  opacity: .7;
  white-space: pre;
  margin-left: .5em;
  font-size: .9em;
}

.monaco-icon-label.nowrap > .monaco-icon-label-container > .monaco-icon-description-container > .label-description {
  white-space: nowrap;
}

.vs .monaco-icon-label > .monaco-icon-label-container > .monaco-icon-description-container > .label-description {
  opacity: .95;
}

.monaco-icon-label.italic > .monaco-icon-label-container > .monaco-icon-name-container > .label-name, .monaco-icon-label.italic > .monaco-icon-label-container > .monaco-icon-description-container > .label-description {
  font-style: italic;
}

.monaco-icon-label.deprecated {
  opacity: .66;
  text-decoration: line-through;
}

.monaco-icon-label.italic:after {
  font-style: italic;
}

.monaco-icon-label.strikethrough > .monaco-icon-label-container > .monaco-icon-name-container > .label-name, .monaco-icon-label.strikethrough > .monaco-icon-label-container > .monaco-icon-description-container > .label-description {
  text-decoration: line-through;
}

.monaco-icon-label:after {
  opacity: .75;
  text-align: center;
  margin: auto 16px 0 5px;
  font-size: 90%;
  font-weight: 600;
}

.monaco-list:focus .selected .monaco-icon-label, .monaco-list:focus .selected .monaco-icon-label:after {
  color: inherit !important;
}

.monaco-list-row.focused.selected .label-description, .monaco-list-row.selected .label-description {
  opacity: .8;
}

.monaco-keybinding {
  align-items: center;
  line-height: 10px;
  display: flex;
}

.monaco-keybinding > .monaco-keybinding-key {
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  margin: 0 2px;
  padding: 3px 5px;
  font-size: 11px;
  display: inline-block;
}

.monaco-keybinding > .monaco-keybinding-key:first-child {
  margin-left: 0;
}

.monaco-keybinding > .monaco-keybinding-key:last-child {
  margin-right: 0;
}

.monaco-keybinding > .monaco-keybinding-key-separator {
  display: inline-block;
}

.monaco-keybinding > .monaco-keybinding-key-chord-separator {
  width: 6px;
}

.monaco-list {
  height: 100%;
  width: 100%;
  white-space: nowrap;
  position: relative;
}

.monaco-list.mouse-support {
  -webkit-user-select: none;
  user-select: none;
}

.monaco-list > .monaco-scrollable-element {
  height: 100%;
}

.monaco-list-rows {
  width: 100%;
  height: 100%;
  position: relative;
}

.monaco-list.horizontal-scrolling .monaco-list-rows {
  width: auto;
  min-width: 100%;
}

.monaco-list-row {
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  overflow: hidden;
}

.monaco-list.mouse-support .monaco-list-row {
  cursor: pointer;
  touch-action: none;
}

.monaco-list-row.scrolling {
  display: none !important;
}

.monaco-list.element-focused, .monaco-list.selection-single, .monaco-list.selection-multiple {
  outline: 0 !important;
}

.monaco-drag-image {
  z-index: 1000;
  border-radius: 10px;
  padding: 1px 7px;
  font-size: 12px;
  display: inline-block;
  position: absolute;
}

.monaco-list-type-filter-message {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  text-align: center;
  white-space: normal;
  opacity: .7;
  pointer-events: none;
  padding: 40px 1em 1em;
  position: absolute;
  top: 0;
  left: 0;
}

.monaco-list-type-filter-message:empty {
  display: none;
}

.monaco-split-view2 {
  width: 100%;
  height: 100%;
  position: relative;
}

.monaco-split-view2 > .sash-container {
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
}

.monaco-split-view2 > .sash-container > .monaco-sash {
  pointer-events: initial;
}

.monaco-split-view2 > .monaco-scrollable-element {
  width: 100%;
  height: 100%;
}

.monaco-split-view2 > .monaco-scrollable-element > .split-view-container {
  width: 100%;
  height: 100%;
  white-space: nowrap;
  position: relative;
}

.monaco-split-view2 > .monaco-scrollable-element > .split-view-container > .split-view-view {
  white-space: initial;
  position: absolute;
}

.monaco-split-view2 > .monaco-scrollable-element > .split-view-container > .split-view-view:not(.visible) {
  display: none;
}

.monaco-split-view2.vertical > .monaco-scrollable-element > .split-view-container > .split-view-view {
  width: 100%;
}

.monaco-split-view2.horizontal > .monaco-scrollable-element > .split-view-container > .split-view-view {
  height: 100%;
}

.monaco-split-view2.separator-border > .monaco-scrollable-element > .split-view-container > .split-view-view:not(:first-child):before {
  content: " ";
  z-index: 5;
  pointer-events: none;
  background-color: var(--separator-border);
  position: absolute;
  top: 0;
  left: 0;
}

.monaco-split-view2.separator-border.horizontal > .monaco-scrollable-element > .split-view-container > .split-view-view:not(:first-child):before {
  height: 100%;
  width: 1px;
}

.monaco-split-view2.separator-border.vertical > .monaco-scrollable-element > .split-view-container > .split-view-view:not(:first-child):before {
  height: 1px;
  width: 100%;
}

.monaco-table {
  height: 100%;
  width: 100%;
  white-space: nowrap;
  flex-direction: column;
  display: flex;
  position: relative;
}

.monaco-table > .monaco-split-view2 {
  border-bottom: 1px solid #0000;
}

.monaco-table > .monaco-list {
  flex: 1;
}

.monaco-table-tr {
  height: 100%;
  display: flex;
}

.monaco-table-th {
  width: 100%;
  height: 100%;
  text-overflow: ellipsis;
  font-weight: bold;
  overflow: hidden;
}

.monaco-table-th, .monaco-table-td {
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-shrink: 0;
  overflow: hidden;
}

.monaco-table > .monaco-split-view2 .monaco-sash.vertical:before {
  content: "";
  left: calc(var(--sash-size) / 2);
  width: 0;
  border-left: 1px solid #0000;
  position: absolute;
}

.monaco-table > .monaco-split-view2, .monaco-table > .monaco-split-view2 .monaco-sash.vertical:before {
  transition: border-color .2s ease-out;
}

.monaco-custom-toggle {
  float: left;
  cursor: pointer;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid #0000;
  border-radius: 3px;
  margin-left: 2px;
  padding: 1px;
  overflow: hidden;
}

.monaco-custom-toggle:hover {
  background-color: var(--vscode-inputOption-hoverBackground);
}

.hc-black .monaco-custom-toggle:hover, .hc-light .monaco-custom-toggle:hover {
  border: 1px dashed var(--vscode-focusBorder);
}

.hc-black .monaco-custom-toggle, .hc-light .monaco-custom-toggle, .hc-black .monaco-custom-toggle:hover, .hc-light .monaco-custom-toggle:hover {
  background: none;
}

.monaco-custom-toggle.monaco-checkbox {
  height: 18px;
  width: 18px;
  opacity: 1;
  border: 1px solid #0000;
  border-radius: 3px;
  margin-left: 0;
  margin-right: 9px;
  padding: 0;
  background-size: 16px !important;
}

.monaco-custom-toggle.monaco-checkbox:not(.checked):before {
  visibility: hidden;
}

.monaco-findInput {
  position: relative;
}

.monaco-findInput .monaco-inputbox {
  width: 100%;
  font-size: 13px;
}

.monaco-findInput > .controls {
  position: absolute;
  top: 3px;
  right: 2px;
}

.vs .monaco-findInput.disabled {
  background-color: #e1e1e1;
}

.vs-dark .monaco-findInput.disabled {
  background-color: #333;
}

.monaco-findInput.highlight-0 .controls, .hc-light .monaco-findInput.highlight-0 .controls {
  animation: .1s linear monaco-findInput-highlight-0;
}

.monaco-findInput.highlight-1 .controls, .hc-light .monaco-findInput.highlight-1 .controls {
  animation: .1s linear monaco-findInput-highlight-1;
}

.hc-black .monaco-findInput.highlight-0 .controls, .vs-dark .monaco-findInput.highlight-0 .controls {
  animation: .1s linear monaco-findInput-highlight-dark-0;
}

.hc-black .monaco-findInput.highlight-1 .controls, .vs-dark .monaco-findInput.highlight-1 .controls {
  animation: .1s linear monaco-findInput-highlight-dark-1;
}

@keyframes monaco-findInput-highlight-0 {
  0% {
    background: #fdff00cc;
  }

  100% {
    background: none;
  }
}

@keyframes monaco-findInput-highlight-1 {
  0% {
    background: #fdff00cc;
  }

  99% {
    background: none;
  }
}

@keyframes monaco-findInput-highlight-dark-0 {
  0% {
    background: #ffffff70;
  }

  100% {
    background: none;
  }
}

@keyframes monaco-findInput-highlight-dark-1 {
  0% {
    background: #ffffff70;
  }

  99% {
    background: none;
  }
}

.monaco-tl-row {
  height: 100%;
  align-items: center;
  display: flex;
  position: relative;
}

.monaco-tl-indent {
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 16px;
}

.hide-arrows .monaco-tl-indent {
  left: 12px;
}

.monaco-tl-indent > .indent-guide {
  box-sizing: border-box;
  height: 100%;
  border-left: 1px solid #0000;
  transition: border-color .1s linear;
  display: inline-block;
}

.monaco-tl-twistie, .monaco-tl-contents {
  height: 100%;
}

.monaco-tl-twistie {
  text-align: right;
  width: 16px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding-right: 6px;
  font-size: 10px;
  transform: translateX(3px);
  display: flex !important;
}

.monaco-tl-contents {
  flex: 1;
  overflow: hidden;
}

.monaco-tl-twistie:before {
  border-radius: 20px;
}

.monaco-tl-twistie.collapsed:before {
  transform: rotate(-90deg);
}

.monaco-tl-twistie.codicon-tree-item-loading:before {
  animation: 1.25s steps(30, end) infinite codicon-spin;
}

.monaco-tree-type-filter {
  max-width: 200px;
  z-index: 100;
  margin: 0 6px;
  padding: 3px;
  transition: top .3s;
  display: flex;
  position: absolute;
  top: 0;
}

.monaco-tree-type-filter.disabled {
  top: -40px;
}

.monaco-tree-type-filter-grab {
  cursor: grab;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
  display: flex !important;
}

.monaco-tree-type-filter-grab.grabbing {
  cursor: grabbing;
}

.monaco-tree-type-filter-input {
  flex: 1;
}

.monaco-tree-type-filter-input .monaco-inputbox {
  height: 23px;
}

.monaco-tree-type-filter-input .monaco-inputbox > .ibwrapper > .input, .monaco-tree-type-filter-input .monaco-inputbox > .ibwrapper > .mirror {
  padding: 2px 4px;
}

.monaco-tree-type-filter-input .monaco-findInput > .controls {
  top: 2px;
}

.monaco-tree-type-filter-actionbar {
  margin-left: 4px;
}

.monaco-tree-type-filter-actionbar .monaco-action-bar .action-label {
  padding: 2px;
}

.x04LzG_editor {
  flex-grow: 1;
}

._1xxv9q_hidden {
  display: none;
}

/*# sourceMappingURL=index.1e7f6773.css.map */
